import { Alert, Typography } from '@mui/material';
import { ParentLayoutBox } from './welcomeToMi.styled';
import { useTranslation } from 'react-i18next';

const translationJSONPrefix = 'newMarketIntelligencePage';

const WelcomeToMiLayout = () => {
  const { t } = useTranslation();
  return (
    <ParentLayoutBox>
      <Alert severity="info">
        {t(`${translationJSONPrefix}.reports.welcomeToMiLayout.title`)}
      </Alert>
      <Typography variant="caption" sx={{ mt: 2 }}>
        {t(`${translationJSONPrefix}.reports.welcomeToMiLayout.subtitle`)}
      </Typography>
    </ParentLayoutBox>
  );
};

export default WelcomeToMiLayout;
