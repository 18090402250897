/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import { Download, Print } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  BottomToolbar,
  HeaderAppBar,
  HeaderGrid,
  ItemBox,
  PaperBox,
  ReportGrid,
  ReportLabelGrid,
  ReportLayoutGrid,
  ShadowBox,
  StyledLoadingButton,
  StyledPaper,
} from './miReports.styled';
import ViewReportLayout from './components/viewReportLayout';
import NoReportLayout from './components/noReportLayout';
import MiReportsSidebar from './components/sidebar';
import {
  useLazyGetStockSymbolQuery,
  useLazyGetFinancialReportQuery,
  useAddPreferenceMutation,
  useLazyGetPreferencesQuery,
  useLazyGetFinancialReportPdfQuery,
  useAddNewReportMutation,
  useDeletePreferenceMutation,
} from '../../../redux/services/speciphicAsk';
import MiTabs from '../../../components/marketIntelligence/miTabs';
import PageContainer from '../../../components/pageContainer';
import { getTheme } from '../../../themes';
import ReportIconSvg from '../../../assets/report-icon.js';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import {
  MARKET_INTELLIGENCE_LISTED_FEATURE,
  MARKET_INTELLIGENCE_UNLISTED_FEATURE,
  MI_PARAMS,
} from '../../../constants/marketIntelligence';
import MiNavigationDropdown from '../../../components/marketIntelligence/miNavigationDropdown';
import {
  triggerFileDownload,
  triggerFilePrint,
} from '../../../utils/functions.js';
import { useLazyGetAllFinancialReportsQuery } from '../../../redux/services/speciphicAsk';
import { useNavigate, useParams } from 'react-router-dom';
import WelcomeToMiLayout from './components/welcomeToMiLayout/welcomeToMiLayout.component.jsx';

const translationJSONPrefix = 'newMarketIntelligencePage';

const API_FETCH_TIME_PERIOD = 30000; // 30 seconds

const MiReportsPage = () => {
  const { t } = useTranslation();
  const { featureName } = useParams();
  const [feature, setFeature] = useState(
    featureName === MI_PARAMS.UNLISTED
      ? MARKET_INTELLIGENCE_UNLISTED_FEATURE
      : MARKET_INTELLIGENCE_LISTED_FEATURE,
  );
  const [value, setValue] = useState(
    featureName === MI_PARAMS.UNLISTED ? 1 : 0,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentReportId, setCurrentReportId] = useState('');
  const [preferencesIntervalID, setPreferencesIntervalId] = useState();
  const [reportLabel, setReportLabel] = useState('');
  const [reportLayout, setReportLayout] = useState('');
  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState('');

  const [stockKey, setStockKey] = useState('');
  const [reportData, setReportData] = useState({});
  const [isDownloadFormat, setIsDownloadFormat] = useState(false);

  const [showArchives, setShowArchives] = useState(false);

  const handleShowArchives = () => {
    setShowArchives((prev) => !prev);
  };

  // collapsible list openener

  const paperRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    if (paperRef.current) {
      const height = paperRef.current.clientHeight;
      setParentHeight(height);
    }
  }, [reportLayout]);

  // for searching stocks
  const handleUserInput = (event) => {
    setInput(event.target.value);
  };

  // // ask db / report user input
  // const handleAskUserInput = (event) => {
  //   setAskUserInput(event.target.value);
  // };

  const [
    getAllFiancialReports,
    {
      data: allFinancialReports,
      isSuccess: isGetAllFinancialReportsSuccess,
      isLoading: isGetAllFinancialReportsLoading,
      isFetching: isGetAllFinancialReportsFetching,
      isError: isGetAllFinancialReportsError,
    },
  ] = useLazyGetAllFinancialReportsQuery();

  const [
    getStockSymbol,
    {
      data: reportKeys = [],
      isSuccess: isGetReportKeysSuccess,
      isLoading: isGetReportKeysLoading,
      isFetching: isGetReportKeysFetching,
      isError: isGetReportKeysError,
    },
  ] = useLazyGetStockSymbolQuery();

  const [
    getPreferences,
    {
      data: preferences,
      isSuccess: isGetPreferencesSuccess,
      isLoading: isGetPreferencesLoading,
      isFetching: isGetPreferencesFetching,
    },
  ] = useLazyGetPreferencesQuery();

  useEffect(() => {
    getPreferences({ feature: feature });
    getStockSymbol({
      query: '',
      feature: feature,
      status: feature === MARKET_INTELLIGENCE_LISTED_FEATURE ? 1 : 'approved',
    });
  }, [feature]);

  const [
    addPreference,
    {
      isSuccess: isAddPreferenceSuccess,
      isLoading: isAddPreferenceLoading,
      isError: isAddPreferenceError,
    },
  ] = useAddPreferenceMutation();

  const [
    fetchFinancialReport,
    {
      data: financialReport = {},
      isSuccess: isGetFinancialReportSuccess,
      isLoading: isGetFinancialReportLoading,
      isFetching: isGetFinancialReportFetching,
      isError: isGetFinancialReportError,
    },
  ] = useLazyGetFinancialReportQuery();

  const [
    generateNewReport,
    {
      isSuccess: isGenerateReportSuccess,
      isLoading: isGenerateReportLoading,
      isError: isGenerateReportError,
    },
  ] = useAddNewReportMutation();

  const handleGenerateReport = () => {
    generateNewReport({
      report_key: stockKey,
      feature: feature,
    });
  };

  // // ask db

  // const [
  //   findAnswers,
  //   {
  //     data: result = [],
  //     isSuccess: isGetResultsSuccess,
  //     isLoading: isGetResultsLoading,
  //     isError: isGetResultsError,
  //   },
  // ] = useAddConversationMutation();

  // const [
  //   getConversation,
  //   {
  //     data: conversationIdHistory = [],
  //     error: conversationHistoryError,
  //     isLoading: isGetConversationHistoryLoading,
  //     isSuccess: isGetConversationHistorySuccess,
  //     isFetching: isGetConversationHistoryFetching,
  //   },
  // ] = useLazyGetConversationHistoryQuery();

  // const [
  //   getFileCollectionConversationHistory,
  //   {
  //     data: fileCollectionConversationHistory = [],
  //     error: fileCollectionConversationHistoryError,
  //     isLoading: isGetFileCollectionConversationHistoryLoading,
  //     isSuccess: isGetFileCollectionConversationHistorySuccess,
  //     isFetching: isGetFileCollectionConversationHistoryFetching,
  //   },
  // ] = useLazyGetConversationCollectionHistoryQuery();

  const [
    deletePreference,
    {
      isSuccess: isDeletePreferenceSuccess,
      isLoading: isDeletePreferenceLoading,
      isError: isDeletePreferenceError,
    },
  ] = useDeletePreferenceMutation();

  // const {
  //   data: fileCollections = [],
  //   isError: isGetFileCollectionsError,
  //   isLoading: isGetFileCollectionsLoading,
  //   isSuccess: isGetFileCollectionsSuccess,
  // } = useGetFileCollectionsQuery({
  //   include: [
  //     'name',
  //     'languageModelId',
  //     'languageModelName',
  //     'isEnabled',
  //     'isVisible',
  //     'displayOrder',
  //   ],
  //   isStoreCreated: true,
  // });

  useEffect(() => {
    if (stockKey.length > 0 || isGetPreferencesSuccess) {
      getAllFiancialReports({ key: stockKey });
    }
  }, [stockKey, preferences]);

  // useEffect(() => {
  //   if (marketIntelligenceConfig && fileCollections?.length) {
  //     const selectedFC = fileCollections.find(
  //       (fileCollection) =>
  //         fileCollection?.id ===
  //         marketIntelligenceConfig?.process_report_file_collection_id,
  //     );

  //     if (selectedFC) {
  //       setMiCollection(selectedFC);
  //       // getFileCollectionConversationHistory({
  //       //   Id: marketIntelligenceConfig?.process_report_file_collection_id,
  //       // });
  //     }
  //   }
  // }, [
  //   getMarketIntelligenceConfig,
  //   isGetFileCollectionsSuccess,
  //   fileCollections,
  // ]);

  // useEffect(() => {
  //   const fetchConversationsAsync = async () => {
  //     try {
  //       if (miFileCollection?.id) {
  //         await fetchConversation();
  //       }
  //     } catch (error) {
  //       console.error('Error fetching conversation:', error);
  //     }
  //   };

  //   fetchConversationsAsync();
  // }, [miFileCollection]);

  // const checkAndInitializeAskDB = (fileCollectionConversations) => {
  //   if (fileCollectionConversations && fileCollectionConversations.length > 0) {
  //     if (stockKey?.length > 0) {
  //       fileCollectionConversations = fileCollectionConversations.filter(
  //         (conversation) => {
  //           const { filters } = conversation;
  //           if (filters && filters[REPORT_KEY]) {
  //             return filters[REPORT_KEY] === stockKey;
  //           }
  //           return false;
  //         },
  //       );
  //     } else {
  //       fileCollectionConversations = fileCollectionConversations.filter(
  //         (conversation) => {
  //           const { filters } = conversation;
  //           return !filters || Object.keys(filters).length === 0;
  //         },
  //       );
  //     }

  //     const conversationSorted = [...fileCollectionConversations].sort(
  //       (conversationOne, conversationTwo) => {
  //         return new Date(conversationOne.timestamp) <
  //           new Date(conversationTwo.timestamp)
  //           ? 1
  //           : -1;
  //       },
  //     );

  //     // Return latest sorted conversation
  //     return conversationSorted.length > 0 ? conversationSorted[0] : {};
  //   } else {
  //     return {};
  //   }
  // };

  // const handleAskKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     handleUserInputSubmit(e);
  //   }
  // };

  // const generateUniqueId = () => {
  //   return 'unique-id-' + Math.random().toString(36);
  // };

  // const handleUserInputSubmit = () => {
  //   if (!isGetResultsLoading && askUserInput.trim()) {
  //     const currentTime = moment();
  //     const newUserMessage = {
  //       collectionId: miFileCollection?.id || 'default_collection_id',
  //       conversation_id: conversation
  //         ? conversation.id
  //         : 'default_conversation_id',
  //       hasError: false,
  //       id: generateUniqueId(),
  //       query: askUserInput,
  //       queryCreatedAt: currentTime.format('YYYY-MM-DDTHH:mm:ssZ'),
  //       results: {},
  //       timestamp: currentTime.format('YYYY-MM-DDTHH:mm:ssZ'),
  //       user: 'user',
  //     };

  //     setPendingUserMessage(newUserMessage);

  //     setConversationHistory((prevHistory) => [...prevHistory, newUserMessage]);

  //     findAnswers({
  //       collectionId: miFileCollection?.id,
  //       query: askUserInput,
  //       acceptLanguage: 'en-US',
  //       includeMetadata: true,
  //       conversationId: conversation ? conversation.id : null,
  //       chatHistoryId: null,
  //       ...(stockKey && stockKey.length > 0
  //         ? {
  //             filters: {
  //               [`${REPORT_KEY}`]: stockKey,
  //             },
  //           }
  //         : {}),
  //     });

  //     setAskUserInput('');
  //   }
  // };

  // useEffect(() => {
  //   if (result && isGetResultsSuccess && pendingUserMessage) {
  //     const currentTime = moment();
  //     const updatedUserMessage = {
  //       ...pendingUserMessage,
  //       results: {
  //         answer: result.hasError ? result.message : result.answer,
  //         meta: result?.meta || [],
  //       },
  //       timestamp: currentTime.format('YYYY-MM-DDTHH:mm:ssZ'),
  //     };

  //     // Update conversation history with the updated user message
  //     setConversationHistory((prevHistory) =>
  //       prevHistory.map((msg) =>
  //         msg.id === pendingUserMessage.id ? updatedUserMessage : msg,
  //       ),
  //     );

  //     // Clear the pending user message
  //     setPendingUserMessage(null);
  //   }
  // }, [isGetResultsSuccess, result, pendingUserMessage]);

  // useEffect(() => {
  //   if (
  //     isGetResultsError &&
  //     (!result || result.length === 0) &&
  //     pendingUserMessage
  //   ) {
  //     const currentTime = moment();
  //     const updatedUserMessage = {
  //       ...pendingUserMessage,
  //       results: {
  //         answer: t('conversationPage.errorMessage'),
  //         meta: [],
  //       },
  //       timestamp: currentTime.format('YYYY-MM-DDTHH:mm:ssZ'),
  //     };

  //     setConversationHistory((prevHistory) =>
  //       prevHistory.map((msg) =>
  //         msg.id === pendingUserMessage.id ? updatedUserMessage : msg,
  //       ),
  //     );

  //     setPendingUserMessage(null);
  //   }
  // }, [result, isGetResultsError, pendingUserMessage]);

  // PDF download
  const [
    fetchFinancialReportPDf,
    {
      data: pdfResult = {},
      isSuccess: isGetPdfResultSuccess,
      isLoading: isGetPdfResultLoading,
      isFetching: isGetPdfResultFetching,
    },
  ] = useLazyGetFinancialReportPdfQuery();

  const handlePrintPdf = (method) => {
    if (method == 'print') {
      setIsDownloadFormat(false);
      fetchFinancialReportPDf({
        report_id: reportData?.reportId,
        report_format: 'pdf',
        download_report: false,
      });
    }
  };

  const handleDownloadReportPdf = (method) => {
    if (method == 'download') {
      setIsDownloadFormat(true);
      fetchFinancialReportPDf({
        report_id: reportData?.reportId,
        report_format: `pdf`,
        download_report: true,
      });
    }
  };

  useEffect(() => {
    if (isGetPdfResultSuccess && pdfResult && isDownloadFormat) {
      triggerFileDownload(pdfResult, `${stockKey}.pdf`, 'application/pdf');
    }
  }, [isGetPdfResultSuccess, pdfResult, isDownloadFormat]);

  useEffect(() => {
    if (isGetPdfResultSuccess && pdfResult && !isDownloadFormat) {
      triggerFilePrint(pdfResult, 'application/pdf', t);
    }
  }, [isGetPdfResultSuccess, pdfResult, isDownloadFormat]);

  // for autocomplete search

  useEffect(() => {
    if (isGetPreferencesSuccess && preferences) {
      setSelectedSymbols(preferences);
      setLoading(false);
    }
  }, [isGetPreferencesSuccess, preferences]);

  const handleSelectSymbolChange = (event, value) => {
    if (
      feature === MARKET_INTELLIGENCE_LISTED_FEATURE &&
      value &&
      !selectedSymbols.some((option) => option.symbol === value.symbol)
    ) {
      setSelectedSymbols((prevSelected) => [...prevSelected, { ...value }]);
      addPreference({
        feature: feature,
        key: value.symbol,
      });
    } else {
      if (
        value &&
        !selectedSymbols.some((option) => option.symbol === value.key)
      ) {
        addPreference({
          feature: feature,
          key: value.key,
        });
      }
    }
    setShowArchives(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getStockSymbol({
        query: input.length > 0 ? input : ``,
        feature: feature,
        status: feature === MARKET_INTELLIGENCE_LISTED_FEATURE ? 1 : 'approved',
      });
    }, 400);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [input]);

  // useEffect(() => {
  //   const fetchConversationsAsync = async () => {
  //     if (stockKey === '') {
  //       try {
  //         await fetchConversation();
  //       } catch (error) {
  //         console.error('Error fetching conversation:', error);
  //       }
  //     }
  //   };

  //   // Trigger fetch only when stockKey is empty
  //   fetchConversationsAsync();
  // }, [stockKey]);

  const resetToAskDb = async () => {
    setReportLayout('askDb');
    setCurrentReportId('');
    setReportLabel('');
    //setInput('');
    setSelectedSymbols([]);
    setReportData({});
    setStockKey('');
  };

  const switchToReport = () => {
    if (reportData?.status === 'READY') {
      setReportLayout('report');
    } else {
      setReportLayout('noReport');
    }
  };

  const handleSelectReport = (report) => {
    // setConversationHistory(null);
    if (feature === MARKET_INTELLIGENCE_UNLISTED_FEATURE && report) {
      const {
        reportId,
        symbol,
        companyName,
        key,
        id,
        status,
        lastUpdatedDate,
      } = report;
      setReportData(report);
      setCurrentReportId(id);
      setReportLabel(companyName);
      setStockKey(symbol);
      if (reportId && key && ['READY', 'OBSOLETE'].includes(status)) {
        fetchFinancialReport({ report_id: reportId });
        setReportLayout('report');
      } else {
        setReportLayout('noReport');
      }
    } else if (feature == MARKET_INTELLIGENCE_LISTED_FEATURE && report) {
      const { reportId, symbol, description, status, id, lastUpdatedDate } =
        report;
      setReportData(report);
      setCurrentReportId(id);
      setReportLabel(description);
      setStockKey(symbol);
      if (reportId && symbol && ['READY', 'OBSOLETE'].includes(status)) {
        fetchFinancialReport({ report_id: reportId });
        setReportLayout('report');
      } else {
        setReportLayout('noReport');
      }
    }
    setShowArchives(false);
  };

  const handleDeletePreference = (key) => {
    deletePreference({ key: key, feature });
    resetToAskDb();
  };

  useEffect(() => {
    if (isDeletePreferenceSuccess) {
      resetToAskDb();

      dispatch(
        pushToast({
          message: t(
            `${translationJSONPrefix}.reports.toasts.deletePreferenceSuccess`,
          ),

          severity: `success`,
        }),
      );
    }
  }, [isDeletePreferenceSuccess]);

  useEffect(() => {
    if (isGetFinancialReportError) {
      setReportLayout('noReport');
    }
  }, [isGetFinancialReportError]);

  useEffect(() => {
    const basePath = location.pathname.replace(`/${featureName}`, '');

    const mappedFeatureName =
      feature === MARKET_INTELLIGENCE_LISTED_FEATURE
        ? MI_PARAMS.LISTED
        : MI_PARAMS.UNLISTED;

    if (featureName !== mappedFeatureName) {
      navigate(`${basePath}/${mappedFeatureName}`);
    }
  }, [feature, featureName]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setFeature(
      newValue === 1
        ? MARKET_INTELLIGENCE_UNLISTED_FEATURE
        : MARKET_INTELLIGENCE_LISTED_FEATURE,
    );
    setLoading(true);
    //reset
    resetToAskDb();
    clearInterval(preferencesIntervalID);
  };

  useEffect(() => {
    getPreferences({ feature });
    // Interval logic
    const intervalId = setInterval(() => {
      getPreferences({ feature });
    }, API_FETCH_TIME_PERIOD);
    setPreferencesIntervalId(intervalId);
    return () => {
      clearInterval(intervalId);
    };
  }, [feature]);

  useEffect(() => {
    if (isAddPreferenceSuccess) {
      //setInput('');
      dispatch(
        pushToast({
          message: t(
            `${translationJSONPrefix}.reports.toasts.addPreferenceSuccess`,
          ),

          severity: `success`,
        }),
      );
    }
  }, [isAddPreferenceSuccess]);

  const handleReportChange = (report) => {
    setReportLayout('report');
    setReportData(report);
    setCurrentReportId(report?.id);
    fetchFinancialReport({ report_id: report?.id });
  };

  // const fetchConversation = async () => {
  //   setConversationHistory(null);

  //   try {
  //     const conversationResponse = await getFileCollectionConversationHistory({
  //       Id: marketIntelligenceConfig?.process_report_file_collection_id,
  //     }).unwrap();

  //     const latestConversation = checkAndInitializeAskDB(conversationResponse);

  //     setConversation(latestConversation);

  //     if (latestConversation?.id) {
  //       // Fetch detailed information for the latest conversation
  //       const detailedConversation = await getConversation({
  //         collectionId: miFileCollection?.id,
  //         conversationId: latestConversation.id,
  //       }).unwrap();

  //       // Update the state with the details of the latest conversation
  //       setConversationHistory(detailedConversation);
  //     } else {
  //       setConversationHistory([]);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching conversation', error);
  //     setConversationHistory([]);
  //   }
  // };

  // const handleSwitchQnA = async () => {
  //   setReportLayout('askDb');
  //   setInput('');
  //   await fetchConversation();
  // };

  useEffect(() => {
    if (isGetPreferencesSuccess) {
      const report = preferences?.find(
        (report) => report.key === stockKey || report.symbol === stockKey,
      );
      if (
        reportLayout !== 'askDb' &&
        reportData?.status !== report?.status &&
        showArchives === false
      ) {
        handleSelectReport(report);
      }
    }
  }, [isGetPreferencesFetching]);

  useEffect(() => {
    if (
      Object.keys(reportData).length === 0 &&
      preferences &&
      preferences.length > 0
    ) {
      handleSelectReport(preferences[0]);
    }
    if (preferences?.length === 0) {
      setReportLabel('');
      setStockKey('');
      setReportLayout('welcomeMi');
    }
  }, [reportData.length, preferences?.length]);

  // useEffect(() => {
  //   if (conversationIdHistory !== null && isGetConversationHistorySuccess) {
  //     setConversationHistory(
  //       formatConversationChatHistory(conversationIdHistory),
  //     );
  //   }
  // }, [isGetConversationHistoryFetching]);

  return (
    <ThemeProvider theme={getTheme('newMi')}>
      <PageContainer background={'secondary'}>
        <ShadowBox>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item>
              <MiTabs handleTabChange={handleTabChange} value={value} />
            </Grid>
            <Grid item>{/* <MiNavigationDropdown /> */}</Grid>
          </Grid>
          {loading ? (
            <LinearProgress />
          ) : (
            <PaperBox ref={paperRef}>
              <StyledPaper elevation={3}>
                <ItemBox>
                  <HeaderAppBar>
                    <Toolbar
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <HeaderGrid container>
                        <Grid item xs={2.5}>
                          <Typography variant="body1">
                            Reports{' '}
                            {preferences?.length > 0 && (
                              <> ({preferences?.length})</>
                            )}
                          </Typography>
                        </Grid>
                        <ReportLabelGrid container item xs={9.5}>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h4">
                              {reportLabel ? (
                                reportLabel
                              ) : (
                                <>
                                  {/* {t(
                                    `${translationJSONPrefix}.reports.askDbLabel`,
                                  )} */}
                                </>
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={8}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {stockKey.length > 0 && (
                                <>
                                  <LoadingButton
                                    variant="contained"
                                    sx={{ marginLeft: 1 }}
                                    onClick={handleGenerateReport}
                                    loading={isGenerateReportLoading}
                                    startIcon={<ReportIconSvg />}
                                  >
                                    {reportData?.status === 'READY' ||
                                    isGetFinancialReportFetching
                                      ? t(
                                          `${translationJSONPrefix}.reports.buttons.generateReport`,
                                        )
                                      : t(
                                          `${translationJSONPrefix}.reports.buttons.generateStockReport`,
                                        )}
                                  </LoadingButton>
                                </>
                              )}
                            </Box>
                          </Grid>
                        </ReportLabelGrid>
                      </HeaderGrid>
                    </Toolbar>
                    <Divider />
                  </HeaderAppBar>
                  <Grid container sx={{ overflowY: 'hidden', height: '100%' }}>
                    <Grid
                      item
                      xs={2.5}
                      sx={{
                        overflowY: 'auto',
                        height: '100%',
                      }}
                    >
                      <MiReportsSidebar
                        myStockPreferences={preferences}
                        reportKeys={reportKeys}
                        isGetReportKeysSuccess={isGetReportKeysSuccess}
                        handleSelectReport={handleSelectReport}
                        selectedReportId={currentReportId}
                        reportLabel={reportLabel}
                        onAskDbSelect={resetToAskDb}
                        feature={feature}
                        userInput={input}
                        handleUserInput={handleUserInput}
                        reportIdentifier={stockKey}
                        isDeletePreferenceLoading={isDeletePreferenceLoading}
                        handleDeletePreference={handleDeletePreference}
                        handleSelectSymbolChange={handleSelectSymbolChange}
                        isGetStockSymbolFetching={isGetReportKeysFetching}
                      />
                    </Grid>
                    <ReportLayoutGrid container item xs={9.5}>
                      <ReportGrid item xs={12}>
                        {reportLayout === 'welcomeMi' && <WelcomeToMiLayout />}

                        {reportLayout === 'report' && (
                          <ViewReportLayout
                            reportLabel={reportLabel}
                            isGetFinancialReportFetching={
                              isGetFinancialReportFetching
                            }
                            isGetFinancialReportSuccess={
                              isGetFinancialReportSuccess
                            }
                            financialReport={financialReport}
                            showArchives={showArchives}
                            handleShowArchives={handleShowArchives}
                            reportData={reportData}
                            handleReportChange={handleReportChange}
                            allFinancialReports={allFinancialReports}
                          />
                        )}
                        {/* {reportLayout === 'askDb' &&
                        !isGetConversationHistoryFetching ? (
                          <AskDbLayout
                            userInput={askUserInput}
                            isGetConversationHistoryLoading={
                              isGetConversationHistoryLoading
                            }
                            stockKey={stockKey}
                            conversationHistory={conversationHistory}
                            isConversationLoading={isGetResultsLoading}
                          />
                        ) : (
                          <Box sx={{ margin: 'auto' }}>
                            <LinearProgress />
                          </Box>
                        )} */}
                        {reportLayout === 'noReport' && (
                          <NoReportLayout
                            reportLabel={reportLabel}
                            status={
                              preferences?.find(
                                (report) => report.key === stockKey,
                              )?.status ||
                              preferences?.find(
                                (report) => report.symbol === stockKey,
                              )?.status
                            }
                            stockKey={stockKey}
                            showArchives={showArchives}
                            handleShowArchives={handleShowArchives}
                            allFinancialReports={allFinancialReports}
                            handleReportChange={handleReportChange}
                            reportData={reportData}
                            isGetAllFinancialReportsFetching={
                              isGetAllFinancialReportsFetching
                            }
                          />
                        )}
                      </ReportGrid>
                      <Grid item xs={12} sx={{ backgroundColor: 'white' }}>
                        <BottomToolbar layout={reportLayout}>
                          {/* {reportLayout === 'askDb' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              minWidth="80%"
                            >
                              <TextField
                                fullWidth
                                placeholder={t(
                                  `${translationJSONPrefix}.reports.askTheDatabase`,
                                )}
                                value={askUserInput}
                                onChange={handleAskUserInput}
                                onKeyDown={handleAskKeyDown}
                              />
                              <IconButton
                                sx={{ margin: 1 }}
                                variant="contained"
                                size="small"
                                disabled={askUserInput.length <= 0}
                                onClick={handleUserInputSubmit}
                              >
                                {<SendIconSvg />}
                              </IconButton>
                            </Box>
                          ) : ( */}

                          {reportLayout === 'report' && (
                            <Box
                              gap={2}
                              display="flex"
                              alignItems="flex-end"
                              justifyContent="center"
                              sx={{ float: 'right' }}
                            >
                              <StyledLoadingButton
                                startIcon={<Print />}
                                disabled={
                                  reportData?.status !== 'READY' ||
                                  isGetFinancialReportFetching
                                }
                                variant="outlined"
                                onClick={() => handlePrintPdf('print')}
                                loading={
                                  isGetPdfResultFetching && !isDownloadFormat
                                }
                              >
                                {t(
                                  `${translationJSONPrefix}.reports.buttons.print`,
                                )}
                              </StyledLoadingButton>
                              <StyledLoadingButton
                                startIcon={<Download />}
                                variant="outlined"
                                onClick={() =>
                                  handleDownloadReportPdf('download')
                                }
                                loading={
                                  isGetPdfResultFetching && isDownloadFormat
                                }
                                disabled={
                                  reportData?.status !== 'READY' ||
                                  isGetFinancialReportFetching
                                }
                              >
                                {t(
                                  `${translationJSONPrefix}.reports.buttons.download`,
                                )}
                              </StyledLoadingButton>
                            </Box>
                          )}
                        </BottomToolbar>
                      </Grid>
                    </ReportLayoutGrid>
                  </Grid>
                </ItemBox>
              </StyledPaper>
            </PaperBox>
          )}
        </ShadowBox>
      </PageContainer>
    </ThemeProvider>
  );
};

export default MiReportsPage;
