/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  AppBar,
  Avatar,
  IconButton,
  List,
  Typography,
  ListItemText,
  Box,
  styled,
  ListItemIcon,
  Divider,
  Grid,
} from '@mui/material';
import fontWeights from '../../themes/fontWeights';

export const AppNavBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
  borderBottom: `1px solid ${theme.palette.border.grey}`,
  boxShadow: theme.shadows[26],
  padding: 0,
}));

export const NavListWrap = styled(List)(({ theme }) => ({
  '& .MuiListItem-root ': {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  '& .MuiListItemText-secondary ': {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
}));

export const AvatarLetterWrap = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 'bold',
  color: theme.palette.primary.contrastText,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,

  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '35px',
  },
}));

export const FlexStartGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
}));

export const NavigationTypography = styled(Typography)(
  ({ theme, isMatchingPath }) => ({
    cursor: 'pointer',
    color: isMatchingPath
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
    fontWeight: isMatchingPath ? fontWeights.bold : fontWeights.regular,
    marginTop: theme.spacing(0.25),
  }),
);

export const GridContainerHorizontal = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const GridItemHorizontal = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const BoxWrap = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  justifyContent: 'space-between',
  color: theme.palette.primary.main,

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  paddingRight: theme.spacing(1),
}));

export const StyledMenuIconWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const BoxWrapMobile = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  flexGrow: 1,
  flexDirection: 'column',
  textAlign: 'center',
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const StyledInnerBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const LogoWrap = styled(Typography)(({ theme }) => ({
  width: '100%',
  color: '#EF4F26',
  display: 'flex',
  justifyContent: 'flex-start',

  [theme.breakpoints.down('md')]: {
    width: '80%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100px',
  },
}));

export const SubtitleWrap = styled(Typography)(() => ({}));

export const MenuIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const AccountText = styled(ListItemText)(() => ({
  overflow: 'auto',
}));

export const StyledAvatarAndModelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
}));

export const StyledModelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'center',
  marginRight: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    display: 'None',
  },
}));

export const StyledNameTypography = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.primary.main,
}));

export const StyledModelTypography = styled(Typography)(({ theme }) => ({
  fontWeight: fontWeights.regular,
}));

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  marginRight: 16,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  height: 30,
  width: '1px',
  margin: '0 10px',

  [theme.breakpoints.down('sm')]: {
    display: 'None',
  },
}));
