import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';

import {
  DescriptionStyled,
  ExtPreProButtonsContainer,
  ExtAPISectionTitle,
  InputsContainer,
  PageHeader,
} from '../styled';
import PageContainer from '../../../../components/pageContainer';
import { SECTIONS } from '../../../../constants/drawer';
import { deepCompare, LOAD_TITLE } from '../../../../utils/functions';
import {
  useGetAllExternalAPIQuery,
  useLazyGetFileCollectionSettingsQuery,
  useUpdateFileCollectionSettingsMutation,
  useLazyGetIndividualFileCollectionQuery,
} from '../../../../redux/services/speciphicAsk';
import UnauthorizedMessage from '../../../../components/unauthorized';
import PreprocessorDefaultForm from './default';
import { pushToast } from '../../../../redux/reducers/toasts.slice';
import {
  EXTERNAL_PREPROCESSOR_API_ENDPOINT,
  EXTERNAL_EMBEDDINGS_API_ENDPOINT,
} from '../../../../constants/externalAPIEndpoints';

const translationJSONPrefix = 'filesCollectionSettingsPage';

const unauthorizedTranslationPrefix = `${translationJSONPrefix}.unauthorizedMessages`;

const FILES_COLLECTION = `${translationJSONPrefix}.filesCollection`;
const PAGE_TITLE_SUFFIX = `${translationJSONPrefix}.pageTitleSuffix`;

const API_ENDPOINT = 'external-preprocessors-config';

const PAGE_TITLE = (t) =>
  `${t(`${translationJSONPrefix}.preprocessorConfig.drawerMenuTitle`)} | ${t(
    FILES_COLLECTION,
  )} | ${t(PAGE_TITLE_SUFFIX)}`;

const PreprocessorConfigPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [collectionId, setCollectionId] = useState('');
  const [extPreProInitialState, setExtPreProInitialState] = useState([]);
  const [extPreProCurrentState, setExtPreProCurrentState] = useState([]);

  const {
    data: extPreProAllAPIs,
    error: extPreProError,
    isFetching: extPreProAllAPIsFetching,
  } = useGetAllExternalAPIQuery({
    endpoint: EXTERNAL_PREPROCESSOR_API_ENDPOINT,
  });

  const [
    updateExtPreProData,
    {
      data: updatedExtPreProData,
      isLoading: updatingExtPreProData,
      error: updatedExtPreProError,
      isSuccess: isUpdatedExtPreProSuccess,
      isError: isUpdatedExtPreProError,
    },
  ] = useUpdateFileCollectionSettingsMutation();

  const [
    getFilesCollectionInfo,
    { data: filesCollectionInfo, error: filesCollectionError },
  ] = useLazyGetIndividualFileCollectionQuery();

  const [
    getExtPreProData,
    {
      data: extPreProData,
      error: extPreProDataError,
      isFetching: extPreProDataLoading,
    },
  ] = useLazyGetFileCollectionSettingsQuery();

  useEffect(() => {
    if (extPreProData) {
      setExtPreProInitialState(JSON.parse(JSON.stringify(extPreProData)));
      setExtPreProCurrentState(JSON.parse(JSON.stringify(extPreProData)));
    }
  }, [extPreProData]);

  useEffect(() => {
    if (isUpdatedExtPreProSuccess)
      dispatch(
        pushToast({
          message: t(
            `${translationJSONPrefix}.preprocessorConfig.externalPreprocessor.toastSuccess`,
          ),
          severity: 'success',
        }),
      );
  }, [isUpdatedExtPreProSuccess]);

  useEffect(() => {
    if (isUpdatedExtPreProError)
      dispatch(
        pushToast({
          message: updatedExtPreProError?.data?.message,
          severity: 'error',
        }),
      );
  }, [isUpdatedExtPreProError]);

  useEffect(() => LOAD_TITLE(document, PAGE_TITLE(t)), []);

  useEffect(() => {
    if (params?.collectionId) {
      setCollectionId(params?.collectionId);
      getFilesCollectionInfo({ collectionId: params?.collectionId });
      getExtPreProData({
        collectionId: params?.collectionId,
        endpoint: API_ENDPOINT,
      });
    }
  }, [params?.collectionId]);

  if (filesCollectionError?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
        description={t(`${unauthorizedTranslationPrefix}.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setIsDrawerOpen(res)}
      drawer={SECTIONS.FILES_COLLECTION_SETTINGS}
      collectionId={collectionId}
    >
      <PageHeader>
        {`${filesCollectionInfo?.name || '...'} / ${t(
          `${translationJSONPrefix}.preprocessorConfig.pageHeader`,
        )}`}
      </PageHeader>

      <DescriptionStyled>{collectionId}</DescriptionStyled>

      <Box style={{ width: '65%' }}>
        <PreprocessorDefaultForm
          isDrawerOpen={isDrawerOpen}
          collectionId={collectionId}
        />

        {extPreProCurrentState?.map((ep, i) => (
          <Box key={i}>
            <Divider />

            <InputsContainer>
              <ExtAPISectionTitle>{`${t(
                `${translationJSONPrefix}.preprocessorConfig.externalPreprocessor.title`,
              )} ${i + 1}`}</ExtAPISectionTitle>
              <IconButton
                onClick={() => {
                  const tempExtPr = [...extPreProCurrentState];
                  tempExtPr.splice(i, 1);
                  setExtPreProCurrentState(tempExtPr);

                  updateExtPreProData({
                    collectionId,
                    data: tempExtPr,
                    endpoint: API_ENDPOINT,
                  });
                }}
              >
                <Delete />
              </IconButton>
            </InputsContainer>

            <Box>
              {t(
                `${translationJSONPrefix}.preprocessorConfig.externalPreprocessor.form.ext.title`,
              )}
            </Box>
            <InputsContainer>
              <TextField
                fullWidth
                value={extPreProCurrentState[i]?.ext?.join(',') || ''}
                onChange={(e) => {
                  let tempExtPr = [...extPreProCurrentState];
                  tempExtPr[i].ext = e.target.value
                    ?.replaceAll(' ', '')
                    ?.split(',');
                  setExtPreProCurrentState(tempExtPr);
                }}
                placeholder={t(
                  `${translationJSONPrefix}.preprocessorConfig.externalPreprocessor.form.ext.placeholder`,
                )}
              />
            </InputsContainer>

            <Box>
              {t(
                `${translationJSONPrefix}.preprocessorConfig.externalPreprocessor.form.extAPISelector.title`,
              )}
            </Box>
            <InputsContainer>
              <Select
                fullWidth
                value={extPreProCurrentState[i]?.id}
                onChange={(e) => {
                  let tempExtPr = [...extPreProCurrentState];
                  tempExtPr[i].id = e.target.value;
                  setExtPreProCurrentState(tempExtPr);
                }}
              >
                {extPreProAllAPIsFetching && (
                  <MenuItem value={''} disabled>
                    <ListItemText
                      primary={t(
                        `${translationJSONPrefix}.preprocessorConfig.externalPreprocessor.form.extAPISelector.loading`,
                      )}
                    />
                  </MenuItem>
                )}

                {extPreProAllAPIs?.map((epp, i) => (
                  <MenuItem key={i} value={epp.id}>
                    <ListItemText
                      primary={epp?.name}
                      secondary={epp?.apiEndpoint}
                    />
                  </MenuItem>
                ))}
              </Select>
            </InputsContainer>
          </Box>
        ))}

        <ExtPreProButtonsContainer>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => {
              setExtPreProCurrentState([
                ...extPreProCurrentState,
                {
                  id: '',
                },
              ]);
            }}
          >
            {t(
              `${translationJSONPrefix}.preprocessorConfig.externalPreprocessor.form.buttons.add`,
            )}
          </Button>

          <LoadingButton
            variant="contained"
            loading={updatingExtPreProData}
            disabled={deepCompare(extPreProInitialState, extPreProCurrentState)}
            onClick={() => {
              updateExtPreProData({
                collectionId,
                data: extPreProCurrentState,
                endpoint: API_ENDPOINT,
              });
            }}
          >
            {t(
              `${translationJSONPrefix}.preprocessorConfig.externalPreprocessor.form.buttons.save`,
            )}
          </LoadingButton>
        </ExtPreProButtonsContainer>
      </Box>
    </PageContainer>
  );
};

export default PreprocessorConfigPage;
