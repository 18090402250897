/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState, useContext } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Grid,
  Box,
  LinearProgress,
  Typography,
  Container,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { Launch } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';

import SearchSuggestion from './searchSuggestion';
import {
  ShadowBox,
  AnswerComponentWrapper,
  LoadingTypography,
  ResultBox,
  FoundDataAlert,
  ResultTitle,
  HoveringLink,
  MetaDataGridBox,
  InfographicSVG,
} from './search.page.styled';
import { SearchbarBase } from '../../components/searchbar';
import { Answer, AnswerWithPdf } from '../../components/answer';
import PageContainer from '../../components/pageContainer';
import {
  useGetResultsMutation,
  useAddFeedbackMutation,
  useLazyGetFaqQuery,
  useLazyGetMetaDataPropertiesQuery,
  useLazyGetIndividualFileCollectionQuery,
  useAddMetaDataFiltersMutation,
  useAddConversationMutation,
} from '../../redux/services/speciphicAsk';
import { pushToast } from '../../redux/reducers/toasts.slice';
import isMetadataFunctionalityEnabledInApp from '../../constants/metadata';
import * as PATHS from '../../constants/path';
import { EXTRACTIVE_SEARCH_ENGINE } from '../../constants/searchEngines';
import { FileCollectionContext } from '../../hooks/useContext';
import BooleanField from '../../components/booleanField';
import MetaDataGrid from '../../components/metadataGrid';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';
import isConversationHistoryEnabled from '../../constants/conversationHistory';

const params = (languageCode, searchQuery, collectionId) => {
  return { q: searchQuery, lang: languageCode, cid: collectionId };
};

const SearchPage = () => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [input, setInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isValidResult, setIsValidResult] = useState(false);
  const [inputTextForAlert, setInputTextForAlert] = useState('');
  const [faqList, setFaqList] = useState([]);
  const [languageCode, setLanguageCode] = useState('');
  const [showAnswerContainer, setShowAnswerContainer] = useState(true);
  const [suggestiveSearch, setSuggestiveSearch] = useState(false);
  const [isQueryMetaData, setIsQueryMetaData] = useState(true);
  const [isGetMetaDataGrid, setIsMetaDataGrid] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [gridSize, setGridSize] = useState(3);
  const {
    getCollectionId,
    isFileCollectionChanged,
    getSearchEngine,
    updateCollectionId,
  } = useContext(FileCollectionContext);
  const { getSiteConfig } = useContext(SiteConfigContext);
  const [clearScreen, setClearScreen] = useState(false);
  const [infographics, setInfographics] = useState({
    type: 'text',
    file: '',
    text: '',
  });
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const siteConfig = getSiteConfig();

    setInfographics({
      type: siteConfig?.infographicsType || 'svg',
      file: siteConfig?.infographicsFile || '',
      text: siteConfig?.infographicsText || '',
    });
  }, [getSiteConfig()]);

  const [
    getFaq,
    {
      data: faqs = [],
      isError: isGetFaqError,
      isLoading: isGetFaqLoading,
      isSuccess: isGetFaqSuccess,
      isFetching: isGetFaqFetching,
    },
  ] = useLazyGetFaqQuery();

  useEffect(() => {
    setFaqList(faqs);
  }, [isGetFaqFetching]);

  const [
    addFeedback,
    {
      data: feedbackData = '',
      isError: isAddFeedbackError,
      isLoading: isAddFeedbackLoading,
      isSuccess: isAddFeedbackSuccess,
    },
  ] = useAddFeedbackMutation();

  const [
    findAnswers,
    {
      data: result,
      isSuccess: isGetResultsSuccess,
      isLoading: isGetResultsLoading,
      isError: isGetResultsError,
    },
  ] = useGetResultsMutation();

  const [
    addConversation,
    {
      data: conversation = [],
      isSuccess: isGetConversationSuccess,
      isLoading: isGetConversationLoading,
      isError: isGetConversationError,
    },
  ] = useAddConversationMutation();

  const [
    findMetaData,
    {
      data: response = [],
      isSuccess: isGetMetaDataSuccess,
      isLoading: isGetMetaDataLoading,
      isError: isGetMetaDataError,
    },
  ] = useAddMetaDataFiltersMutation();

  const [
    metadataProperties,
    {
      data: definedMetaProperties = {},
      isError: isGetMetaPropertiesError,
      isLoading: isGetMetaPropertiesLoading,
      isSuccess: isGetMetaPropertiesSuccess,
    },
  ] = useLazyGetMetaDataPropertiesQuery();

  const [
    getFileCollection,
    {
      data: individualFileCollection,
      isError: isGetIndividualFileCollectionError,
      isLoading: isGetIndividualFileCollectionLoading,
      isSuccess: isGetIndividualFileCollectionSuccess,
    },
  ] = useLazyGetIndividualFileCollectionQuery();

  const freshInput = () => {
    const collectionId = getCollectionId();

    if (!collectionId) return;

    getFaq({
      collectionId,
    });
    getFileCollection({
      collectionId,
    });
    setInput('');
    setErrorMessage('');
    setIsValidResult(false);
    setInputTextForAlert('');
    setShowAnswerContainer(true);
    setSuggestiveSearch(false);
    setIsQueryMetaData(true);
    setIsMetaDataGrid(true);
    setPageNo(1);
    setGridSize(3);
  };

  useEffect(() => {
    if (result) {
      if (result?.hasError) {
        setIsValidResult(false);
        setErrorMessage(result.message);
      } else {
        const time = moment();
        setIsValidResult(true);
        setCurrentTime(time.format('YYYY-MM-DDTHH:mm:ssZ'));
      }
      setClearScreen(false);
    }
  }, [isGetResultsSuccess, result]);

  useEffect(() => {
    if (isAddFeedbackSuccess) {
      dispatch(
        pushToast({
          message: t('speciphicAskPage.feedbackSuccess'),
          severity: 'success',
        }),
      );
    }
  }, [isAddFeedbackSuccess]);

  useEffect(() => {
    if (isGetResultsError) {
      setIsValidResult(false);
      setErrorMessage(t('speciphicAskPage.errorResult'));

      dispatch(
        pushToast({
          message: t('speciphicAskPage.errorResult'),
          severity: 'error',
        }),
      );
    }
  }, [isGetResultsError]);

  const handleLanguageChange = (value) => {
    setLanguageCode(value.code);
  };

  const handleClickFetchAnswer = (collectionId, query, languageCode) => {
    if (
      isMetadataFunctionalityEnabledInApp &&
      individualFileCollection?.isMetadataEnabled
    ) {
      metadataProperties({
        collectionId,
      });
    }

    findAnswers({
      collectionId,
      query,
      acceptLanguage: languageCode,
      includeMetadata:
        isMetadataFunctionalityEnabledInApp &&
        individualFileCollection?.isMetadataEnabled &&
        isQueryMetaData,
    });

    setInputTextForAlert(query);
    setClearScreen(true);
    setShowAnswerContainer(false);
    navigate({
      pathname: `${PATHS.SPECIPHIC_ASK}`,
      search: `${createSearchParams(
        params(languageCode, query, collectionId),
      )}`,
    });
    if (
      isMetadataFunctionalityEnabledInApp &&
      isGetMetaDataGrid &&
      individualFileCollection?.isMetadataEnabled
    ) {
      findMetaData({
        collectionId,
        query,
        size: gridSize,
        pageNo: pageNo,
      });
    }
  };

  useEffect(() => {
    if (suggestiveSearch) {
      handleClickFetchAnswer(getCollectionId(), input, languageCode);
      setSuggestiveSearch(false);
    }
  }, [suggestiveSearch, getCollectionId()]);

  // this useEffect for auto fetch answer for url
  useEffect(() => {
    if (!location.search) {
      setShowAnswerContainer(true);
    }

    if (search.get('cid')) {
      const query = search.get('q');
      const languageCode = search.get('lang');
      const collectionId = search.get('cid');

      setInput(query);
      setLanguageCode(languageCode);

      updateCollectionId(collectionId);

      handleClickFetchAnswer(collectionId, query, languageCode);
    } else freshInput();
  }, []);

  useEffect(() => {
    freshInput();

    if (search.get('q')) {
      navigate({
        pathname: `${PATHS.SPECIPHIC_ASK}`,
      });
    }
  }, [isFileCollectionChanged]);

  const handleClickFeedback = (res, bool) => {
    {
      isGetResultsSuccess &&
        addFeedback({
          chatId: result.id,
          isHelpful: bool,
        });
    }
  };

  const handleMetaDataButton = () => {
    const collectionId = getCollectionId();

    navigate(`${PATHS.SEARCH_METADATA_PAGE}/${collectionId}`, {
      state: {
        collectionId: collectionId,
      },
    });
  };

  const handleMetaDataGrid = (value) => {
    setIsMetaDataGrid(value);
  };

  const handleNavigateConversationPath = () => {
    addConversation({
      collectionId: getCollectionId(),
      query: null,
      acceptLanguage: 'en-US',
      includeMetadata: true,
      conversationId: null,
      chatHistoryId: result?.id,
    });
  };

  useEffect(() => {
    const collectionId = getCollectionId();

    if (isGetConversationSuccess) {
      navigate(
        `${PATHS.FILE_COLLECTIONS}/${collectionId}/${PATHS.CONVERSATION}/${conversation[0].conversation_id}`,
        {
          state: {
            fileCollectionId: collectionId,
            conversationId: conversation[0].conversation_id,
            isConversationHistorySent: true,
            time: currentTime,
          },
        },
      );
    }
  }, [isGetConversationSuccess]);

  return (
    <>
      <PageContainer>
        <>
          {infographics?.type === 'svg' ? (
            <InfographicSVG src={infographics?.file} alt="Infographics" />
          ) : (
            <>
              <Typography
                variant="h1"
                sx={{
                  fontSize: '56px',
                  fontWeight: 200,
                  color: '#131E29',
                  marginTop: '100px',
                }}
              >
                {infographics?.text || ''}
              </Typography>
            </>
          )}
        </>

        <Container sx={{ width: '100%', paddingX: { xs: 1, sm: 8 } }}>
          <>
            <ShadowBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchbarBase
                    onSearchClick={() =>
                      handleClickFetchAnswer(
                        getCollectionId(),
                        input,
                        languageCode,
                      )
                    }
                    value={input}
                    setQueryLanguage={handleLanguageChange}
                    onChange={setInput}
                    placeholder={t(
                      'trainedquestionAnswerPage.searchbarBase.placeholder',
                    )}
                  />
                </Grid>
                {isMetadataFunctionalityEnabledInApp &&
                  individualFileCollection?.isMetadataEnabled && (
                    <Grid container item display="flex" xs={12}>
                      <Grid
                        container
                        item
                        xs={8}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ paddingLeft: 4 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          display="flex"
                          justifyContent="flex-start"
                          sx={{ py: 2 }}
                        >
                          <BooleanField
                            value={isQueryMetaData}
                            label={t(
                              'speciphicAskPage.metadataFields.includeMetadataLabel',
                            )}
                            onChange={(value) => setIsQueryMetaData(value)}
                            isLabelAndFieldOnHorizontalLine={true}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          display="flex"
                          justifyContent="space-between"
                          sx={{ py: 2, px: 2 }}
                        >
                          <BooleanField
                            value={isGetMetaDataGrid}
                            label={t(
                              'speciphicAskPage.metadataFields.showMetadataMatchesLabel',
                            )}
                            onChange={(value) => handleMetaDataGrid(value)}
                            isLabelAndFieldOnHorizontalLine={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <HoveringLink
                          aria-label="search"
                          onClick={handleMetaDataButton}
                        >
                          {t(
                            'speciphicAskPage.metadataFields.goToMetadataSearch',
                          )}
                          <Launch sx={{ fontSize: 16, marginLeft: 0.5 }} />
                        </HoveringLink>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </ShadowBox>
            {showAnswerContainer && (
              <SearchSuggestion
                isLoading={isGetFaqFetching}
                faqList={faqList}
                setInput={setInput}
                suggestiveTrue={setSuggestiveSearch}
              />
            )}
          </>

          {!showAnswerContainer ? (
            <ResultBox>
              {isGetResultsLoading ? (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <LinearProgress sx={{ width: '40%' }} />
                  <LoadingTypography
                    variant="h5"
                    paddingTop={1}
                    color="primary"
                  >
                    {t('trainedquestionAnswerPage.loadingMessage')}
                  </LoadingTypography>
                </Box>
              ) : (
                <>
                  {result?.hasError ? (
                    <Alert
                      severity="error"
                      sx={{
                        background: '#D11818',
                        color: '#FFFFFF',
                        '.MuiSvgIcon-root': { color: '#FFFFFF' },
                        borderRadius: 0,
                      }}
                    >
                      {errorMessage}
                    </Alert>
                  ) : (
                    <>
                      {isValidResult && (
                        <>
                          <FoundDataAlert
                            severity="success"
                            sx={{
                              '.MuiSvgIcon-root': { color: '#FFFFFF' },
                              borderRadius: 0,
                            }}
                          >
                            {t(
                              'trainedquestionAnswerPage.alertMessages.foundData',
                              {
                                searchText: inputTextForAlert,
                              },
                            )}
                          </FoundDataAlert>
                        </>
                      )}
                      {isValidResult && (
                        <AnswerComponentWrapper>
                          <ResultTitle>
                            {t('trainedquestionAnswerPage.resultHeader')}

                            {isConversationHistoryEnabled && (
                              <LoadingButton
                                variant="contained"
                                startIcon={<ChatIcon />}
                                size="small"
                                onClick={handleNavigateConversationPath}
                                loading={isGetConversationLoading}
                              >
                                {t('speciphicAskPage.followUpQuestion')}
                              </LoadingButton>
                            )}
                          </ResultTitle>

                          {getSearchEngine() === EXTRACTIVE_SEARCH_ENGINE ? (
                            <Answer
                              title={result?.answer || ''}
                              description={result?.context || ''}
                              meta={result?.originalFileName || ''}
                              feedback={handleClickFeedback}
                              feedbackAPI={{
                                feedbackData,
                                isAddFeedbackError,
                                isAddFeedbackLoading,
                                isAddFeedbackSuccess,
                              }}
                              result={result}
                              query={input}
                            />
                          ) : (
                            <AnswerWithPdf
                              result={result}
                              title={result?.answer || ''}
                              description={result?.context || ''}
                              metas={result?.meta || []}
                              pdfPreview={true}
                              feedback={handleClickFeedback}
                              feedbackAPI={{
                                feedbackData,
                                isAddFeedbackError,
                                isAddFeedbackLoading,
                                isAddFeedbackSuccess,
                              }}
                              query={input}
                              isDefaultFeedbackRequired={true}
                            />
                          )}
                        </AnswerComponentWrapper>
                      )}
                    </>
                  )}
                </>
              )}
            </ResultBox>
          ) : null}
          {!showAnswerContainer && !isGetResultsLoading && (
            <ResultBox>
              <>
                {isMetadataFunctionalityEnabledInApp &&
                  isGetMetaDataGrid &&
                  isGetMetaDataSuccess &&
                  result &&
                  response?.results?.length > 0 &&
                  !clearScreen && (
                    <MetaDataGridBox>
                      <Grid>
                        <Box sx={{ paddingTop: 2 }}>
                          <Typography
                            variant="h4"
                            sx={{ fontWeight: 'bold', fontSize: 20 }}
                          >
                            {t(
                              'speciphicAskPage.metadataFields.metadataGrid.filesRelatedToSearch',
                            )}
                          </Typography>
                        </Box>

                        <MetaDataGrid
                          files={response?.results || []}
                          definedMetaProperties={definedMetaProperties}
                          pageSize={gridSize}
                          onPageSizeChange={(newPageSize) =>
                            setGridSize(newPageSize)
                          }
                          pageNo={pageNo}
                          onPageNoChange={(newPageNo) => setPageNo(newPageNo)}
                          totalPages={`${response?.total_size}`}
                          isMainSearchPage={true}
                          noRowsMessage={t(
                            'speciphicAskPage.metadataFields.metadataGrid.noRowsMessage',
                          )}
                        />
                      </Grid>
                    </MetaDataGridBox>
                  )}
              </>
            </ResultBox>
          )}
        </Container>
      </PageContainer>
    </>
  );
};

export default SearchPage;
